<template>
  <Breadcrumb :urls="urls"  :translationloaded="translationloaded" :translate="translate"></Breadcrumb>
  <WhatsAppButton></WhatsAppButton>
  <div class="account-info-sec" v-if="translationloaded==true">
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <div id="show-hidden-menu" class="dashBoarsBtnMain" @click="toggledashboard()">
            <i class="fa fa-th-large" aria-hidden="true"></i>{{translate('dashboard')}}
          </div>
          <div id ="vdasboard" class="hidden-menu" style="display: none;">
            <SideBar
              :translationloaded="translationloaded"
              :translate="translate"
            ></SideBar>
          </div>
          <div class="mainSideDiplay">
           <SideBar
              :translationloaded="translationloaded"
              :translate="translate"
            ></SideBar>
          </div>
        </div>
        <div class="col-lg-9">
          <div class="ps-prduct_box">
            <template
              v-for="(wishlist_item, index) in fetctCustomerWishlist"
              :key="index"
            >
              <div class="produtDeatilsDivFloat">
                <WishlistCard
                  :product="wishlist_item"
                  :web_admin_url="web_admin_url"
                  @addToCart="addtocart($event)"
                  :cart_ajax="cart_ajax"
                  :cart_product_url_key="cart_product_url_key"
                  :currency="currency"
                  :fevare_required="true"
                  :feverate_status="true"
                  @moveToCart="moveToCart"
                  :translationloaded="translationloaded"
                 :translate="translate"
                >
                </WishlistCard>
              </div>
            </template>
          </div>
          <EmptyItem v-if="fetctCustomerWishlist.length < 1"></EmptyItem>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumb from "../components/Breadcrumb";
import SideBar from "../components/my_account/SideBar";
import WishlistCard from "../components/WishlistCard";
import WhatsAppButton from "@/components/WhatsAppButton";
import Config from "../config";
import { mapActions, mapGetters } from "vuex";
import EmptyItem from "@/components/EmptyItem";
import helper from "@/helper/helper";
import axios from "axios";
export default {
  name: "MyWishlist",
  components: {
    Breadcrumb,
    SideBar,
    WishlistCard,
    EmptyItem,
    WhatsAppButton
  },
   props:[
    'translationloaded',
    'translate'
  ],
  data() {
    return {
      urls: [
        {
          path: "/",
          name: "Home",
        },

        {
          path: "/my-wishlist",
          name: "myWishlist",
        },
      ],
      web_admin_url: "",
      cart_ajax: "closed",
      cart_product_url_key: "",
      loaderColor: "red",
      loaderStyle: {
        width: "40px",
      },
      currency: "",
      guest_id: "",
      cust_id: null,
    };
  },
  mounted() {
    this.getCustomerWishlist();
    this.vendor_url_key = Config.VENDOR_URL_KEY;
    this.web_admin_url = Config.WEB_ADMIN_URL;
    this.currency = Config.CURRECNCY;
    this.guest_id = localStorage.getItem("guestId");
    this.cust_id = localStorage.getItem("custId");
    helper.backtoTop();
    
  },
  methods: {
   toggledashboard()
    {
       var x = document.getElementById("vdasboard");
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
    },
    ...mapActions(["getCustomerWishlist","getCart"]),
    addtocart(productInfo) {
      let urlKey = productInfo.url_key;
      let productQty = productInfo.product_qty;
      let $this = this;
      $this.cart_ajax = "open";
      $this.cart_product_url_key = productInfo.url_key;
      let payload = {
        cusId: $this.cust_id != null ? $this.cust_id : "",
        guestId: $this.cust_id == null ? $this.guest_id : "",
        pincode: $this.pincode,
        urlKey: urlKey,
        productQty: productQty,
        vendorUrlkey: $this.vendor_url_key,
      };

      $this.$store
        .dispatch("addToCart", { payload: payload, toast: $this.$toast })
        .then(function() {
          // console.log(response);
          $this.cart_ajax = "closed";
          $this.cart_product_url_key = "";
          $this.customerCart = $this.$store.state.customer_cart;
          $this.$emit("cartUpdated", $this.customerCart);
        });
    },
    moveToCart(payload){
      
             let $this =this;
              $this.cart_ajax = "open";
              $this.cart_product_url_key = payload.url_key;
              axios.post(`${Config.BASE_URL}Order/UpdateWishLists`,payload,Config.CUSTOMER_API_HEADER).then(function(response){
                    if(response.status==200){
                           $this.cart_ajax = "closed";
                           $this.cart_product_url_key = "";
                          $this.getCustomerWishlist();
                          let data ={
                            "cusId":payload.custId,
                            "guestId":payload.guestId
                          }
                          $this.$toast.success('Product moved to cart successfully');
                          $this.getCart(data).then(function(){
                              $this.customerCart = $this.$store.state.customer_cart;
                              $this.$emit("cartUpdated", $this.customerCart);
                          }).catch(function(){
                             $this.cart_ajax = "closed";
                          });
                          
                    }
              })
    }


  },
  computed: {
    ...mapGetters(["fetctCustomerWishlist"]),
  },
};
</script>
<style scoped>
.ps-product {
  margin-right: 7px;
  margin-left: 7px;
}
.ps-prduct_box {
  min-height: 100px;
}

.whilistIconwrapper .is-active {
  background: #d81c24;
}


</style>
